﻿@import "quasar.variables";

// font path
$dana_font_path: '../assets/fonts/Dana/';

// font faces

// dana
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 10;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-thin.eot');
  src: url($dana_font_path +'eot/dana-fanum-thin.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-thin.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-extralight.eot');
  src: url($dana_font_path +'eot/dana-fanum-extralight.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-extralight.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-extralight.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-light.eot');
  src: url($dana_font_path +'eot/dana-fanum-light.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-light.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-medium.eot');
  src: url($dana_font_path +'eot/dana-fanum-medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-demibold.eot');
  src: url($dana_font_path +'eot/dana-fanum-demibold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-demibold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-demibold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 750;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-ultrabold.eot');
  src: url($dana_font_path +'eot/dana-fanum-ultrabold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-ultrabold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-ultrabold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-extrabold.eot');
  src: url($dana_font_path +'eot/dana-fanum-extrabold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-extrabold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-extrabold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-black.eot');
  src: url($dana_font_path +'eot/dana-fanum-black.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-black.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-bold.eot');
  src: url($dana_font_path +'eot/dana-fanum-bold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-bold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-regular.eot');
  src: url($dana_font_path +'eot/dana-fanum-regular.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-regular.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}


/**
Iranic fonts
**/


@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 10;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-thinitalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-thinitalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-thinitalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-thinitalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-extralightitalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-extralightitalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-extralightitalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-extralightitalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-lightitalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-lightitalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-lightitalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-lightitalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-mediumitalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-mediumitalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-mediumitalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-mediumitalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-demibolditalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-demibolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-demibolditalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-demibolditalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 750;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-ultrabolditalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-ultrabolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-ultrabolditalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-ultrabolditalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-extrabolditalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-extrabolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-extrabolditalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-extrabolditalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-blackitalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-blackitalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-blackitalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-blackitalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-bolditalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-bolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-bolditalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-bolditalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url($dana_font_path +'eot/dana-fanum-regularitalic.eot');
  src: url($dana_font_path +'eot/dana-fanum-regularitalic.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url($dana_font_path +'woff2/dana-fanum-regularitalic.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url($dana_font_path +'woff/dana-fanum-regularitalic.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

// page
.full-page {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

// pattern background
.pattern {
  background-image: url("../../public/img/hamiane-pattern.jpg");
  background-repeat: repeat;
}

// global font settings
body {
  font-family: dana, "Segoe UI";
  font-weight: 300;
  letter-spacing: -0.01562em !important;
}

h6 {
  font-size: 13px !important;
}

h5 {
  font-size: 14px !important;
}

h4 {
  font-size: 15px !important;
}

h3 {
  font-size: 16px !important;
}

h2 {
  font-size: 20px !important;
}

h1 {
  font-size: 25px !important;
}

// background color
body {
  background-color: #F5F5F5 !important;
}

// scrollbar
* {
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F1F1F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// hover
.hover-primary {
  transition: 0.3s;
}

.hover-primary:hover {
  transition: 0.3s;
  color: var(--q-primary) !important;
}

.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  transition: 0.3s;
  box-shadow: 0px 10px 15px #d1d9e6, -0px -10px 15px #fff !important;
}

.faded-shadow {
  transition: 0.3s;
  box-shadow: 0px 5px 10px #d1d9e6, -0px -5px 10px #fff !important;
}

.faded-shadow:hover {
  transition: 0.3s;
  box-shadow: 0px 10px 15px #d1d9e6, -0px -10px 15px #fff !important;
}

// borders
.border-inactive {
  transition: 0.3s;
}

.border-active {
  transition: 0.3s;
  color: var(--q-primary);
}

.border {
  border: 1px solid;
}

.border-solid {
  border: 1px solid;
}

.border-medium {
  border: 2px solid;
}

.border-dashed {
  border: 1px dashed;
}

.border-left {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.border-right {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.border-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.border-top {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

// border radius
.border-radius {
  border-radius: 10px;
}

// grey-filter
.greyscale {
  filter: grayscale(1);
}

.greyscale:hover {
  filter: grayscale(0) !important;
}

// full-width
.width-full {
  width: 100vw;
  position: relative;
  right: 5%;
}

// ellipsis
@for $i from 1 through 10 {
  $base: 1;
  $line-clamp: $base * $i;
  .line-clamp-#{$line-clamp} {
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// text
.text-vertical {
  writing-mode: vertical-rl;
}

// aparat iframe styles
.h_iframe-aparat_embed_frame {
  position: relative;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;

  iframe {
    border: none;
    border-radius: 10px;
  }
}

.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}

.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.link-button {
  text-decoration: none;

  span {
    padding: 7px;
    border-radius: 3px;
  }
}

iframe {
  border: none;
  border-radius: 5px;
}
